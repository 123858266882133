import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "Financial system",
  "route": "/financial-system"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Financial system`}</h1>
    <h2>{`Deposit and withdrawal`}</h2>
    <p>{`Smartlike implements a decentralized deposit and withdrawal system. Deposits are regular donations, end-to-end payments, registered with the system via payment receipts. Withdrawal transactions are protected by cryptographic signatures and chaining and operate in a trust-less manner.`}</p>
    <p>{`The service employs a hybrid from trusted relationships between creators and their audience for private and secure end-to-end payments and trust-less horizontally scalable public ledger technology for transaction processing without cryptocurrency.`}</p>
    <p>{`Donors deposit funds at creators they trust and then use the accounts for anonymous fee-less micro-donations. Deposits are validated on the trusted level: recipient publishes a confirmation transaction that there was a deposit outside Smartlike, adds funds to donor's account. Transactions are cryptographically validated by the network nodes.`}</p>
    <p><strong parentName="p">{`The cycle:`}</strong>{` donors make direct donations to creators outside Smartlike. Creators confirm receipts by filling donors' Smartlike accounts with the exact same amounts. The donors then use the funds to send micro-donations to their other favourite creators. Creators accumulate micro-donations and fill other donors' Smartlike accounts in exchange to direct donations thus creating a cycle. The sum of all accounts is always zero. Creators are given credit lines (controlled by user consensus) to enable the cycle. Total credit amount will grow or shrink depending on demand. The process is also controlled by user consensus. The platform can work with any currency (`}<a parentName="p" {...{
        "href": "https://smartlike.org/rates"
      }}>{`currently suppurting`}</a>{`). Exchange rates are determined by user consensus as well (`}<a parentName="p" {...{
        "href": "https://github.com/smartlike-org/smartlike/tree/main/crates/vote"
      }}>{`voting software`}</a>{`). Users select a currency for their account when they add funds for the first time. Transfers are automatically converted with double float precision, no manual exchanges.`}</p>
    <h2>{`Credit lines`}</h2>
    <p>{`Credit lines are used to support Smartlike donation cycle. Creators are given credit lines that can be used to fill user Smartlike accounts in exchange to regular donations outside Smartlike. Credit lines are then repaid from micro-donations and smartlikes.`}</p>
    <p>{`Smartlike doesn't create monetary assets but rather works with zero balance. Credit line is only a means of enabling micro-donation cycle between creator and their fans. They don't have any other value on its own.`}</p>
    <p>{`Credit lines will be adjusted whenever necessary by creators' consensus. During beta credit lines are initialized from the number of creator subscribers on YouTube, Twitter and Telegram accounts and are adjusted by Smartlike until consensus algorithms are tuned.`}</p>
    <p>{`An example: A YouTube blogger connects its Smartlike account to YouTube and register's a regular billing address with Smartlike. Now she can encourage their users to donate and get their Smartlike accounts filled with donation amounts. The blogger receives money, while users can send commission-free micro-donations to their other favorite bloggers. The creator's account becomes negative and balances back when other users send him micro-donations on Smartlike.`}</p>
    <h2>{`Bank connection`}</h2>
    <p>{`Integration with the banking system is rather loose. In the simplest case, I give my blogger $10 in cash, he will just hit the "Accept" button in his donation inbox on smartlike.org and I will see the exact same amount on my Smartlike account, while the blogger can go and buy himself a coffee.`}</p>
    <p>{`The process is automated with `}<a parentName="p" {...{
        "href": "https://github.com/smartlike-org/smartlike/tree/main/crates/gateway"
      }}>{`decentralized payment gateways`}</a>{`. When I transfer the $10 to my blogger via PayPal, the gateway receives notification and automatically adds funds to my Smartlike account. If my blogger doesn't want to host his own payment gateway she can choose to trust one hosted by someone else when adding his PayPal address to his profile. Everything is decentralized.`}</p>
    <p>{`Currencies are automatically converted with double float precision according to `}<a parentName="p" {...{
        "href": "https://smartlike.org/rates"
      }}>{`consensus exchange rates`}</a>{`. Trading, investing and speculation are disincentivized.`}</p>
    <h2>{`The Goal`}</h2>
    <p>{`We seek to enable close to loss-less micro-donations (no service fees, no volatility, no speculation). Users with an internet connected smartphone and an ordinary bank account should see no additional costs.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      